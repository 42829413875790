<template>
  <div>
    <banded-section
      title="Organisation"
      tooltip="This page is visible to hypefactors staff only."
      :collapsable="false"
    >
      <div class="StaffNewsrooms-Search">
        <heading size="3">
          Change Email Logo
        </heading>

        <div class="columns">
          <div class="column is-6 is-offset-3">
            <form enctype="multipart/form-data" @submit.prevent="submit">
              <form-field
                :validator="$v.form.organisationId"
                label="Organisation"
              >
                <basic-select
                  :value="organisationId"
                  :values="fetchedOrganisations"
                  :remote-method="queryForOrganisations"
                  :loading="fetchingOrganisations"
                  remote
                  filterable
                  clearable
                  label-prop="name"
                  value-prop="id"
                  key-prop="id"
                  placeholder="Choose an Organisation"
                  popper-class="OrganisationsModal"
                  @input="handleOrganisationSelect"
                />
              </form-field>

              <template v-if="organisationId">
                <form-field
                  v-if="currentLogo"
                  label="Current Logo"
                >
                  <img :src="currentLogo" style="height: 100px" alt="">
                </form-field>

                <form-field
                  :validator="$v.form.logo"
                  label="Select Logo"
                >
                  <el-upload
                    ref="uploader"
                    :on-change="file => form.logo = file || null"
                    :on-remove="handleFileRemoval"
                    :on-exceed="handleFileExceed"
                    v-bind="fileUpload.options"
                    class="m-b-m"
                  >
                    <i class="el-icon-upload" />
                    <div class="el-upload__text" v-html="$t('forms.drop_files_here')" />
                  </el-upload>

                  <div class="m-b-m has-text-grey">
                    Be mindful of the image size that will be uploaded, as the image on the email itself will have a max height of <strong>50px</strong>.
                  </div>
                </form-field>
              </template>

              <button
                v-if="organisationId"
                :class="{ 'is-loading': form.isLoading }"
                :disabled="!(organisationId && form.logo) || form.isLoading"
                type="submit"
                class="button is-primary"
              >
                Update Logo
              </button>

              <v-button
                v-if="currentLogo"
                type="button"
                class="button ml-2"
                @click="removeLogo"
              >
                Remove current logo
              </v-button>
            </form>
          </div>
        </div>
      </div>
    </banded-section>
  </div>
</template>

<script>
import Form from '@/services/forms/Form'
import { required } from 'vuelidate/lib/validators'

import { debounceMixin } from '@hypefactors/shared/js/mixins/debounceMixin'

function createForm () {
  return new Form({
    logo: { value: null, rules: { required } }
  })
}

export default {
  mixins: [debounceMixin(['queryForOrganisations'], 250)],

  data: () => ({
    form: createForm(),
    organisationId: null,
    currentLogo: null,
    fetchedOrganisations: [],
    fetchingOrganisations: false
  }),

  computed: {
    fileUpload () {
      return {
        options: {
          limit: 1,
          multiple: false,
          autoUpload: false,
          accept: 'image/png,image/jpg,image/jpeg',
          action: '',
          drag: true,
          headers: {
            Authorization: `Bearer ${this.authToken}`
          }
        }
      }
    }
  },

  validations () {
    return {
      form: this.form.rules()
    }
  },

  mounted () {
    this.queryForOrganisations()
  },

  methods: {
    queryForOrganisations (search = '') {
      this.fetchingOrganisations = true

      const payload = {
        params: {
          search,
          limit: 20
        }
      }

      return this.fetchOrganisations(payload)
    },

    fetchOrganisations (payload) {
      this.$api.get('staff/organisations', payload)
        .then((response) => {
          this.fetchedOrganisations = response.data.data
        })
        .catch((error) => {
          this.$displayRequestError(error, this.$t('errors.cannot_fetch_organisations'))
        })
        .finally(() => {
          this.fetchingOrganisations = false
        })
    },

    async handleOrganisationSelect (value) {
      const organisation = await this.findOrganisationById(value)

      this.organisationId = value
      this.currentLogo = organisation.email_logo
    },

    /**
     * Find an organisation by ID
     * @param id
     * @return {HF_Organisation}
     */
    findOrganisationById (id) {
      return this.fetchedOrganisations.find((organisation) => organisation.id === id)
    },

    handleFileExceed () {
      this.$alert('You can only upload one file at a time. Please remove the old one.')
    },

    handleFileRemoval () {
      this.form.logo = null
      this.validationResults = []
      this.isValidated = false
    },

    async submit () {
      this.$v.form.$touch()
      if (this.$v.form.$error) return

      try {
        this.form.setPayloadTransformer((payload) => {
          let formData = new FormData()

          formData.append('logo', payload.logo.raw)

          return formData
        })

        this.form.submit('post', `/staff/organisations/${this.organisationId}/set-email-logo`, {
          headers: {
            'Content-Type': 'multipart/form-data'
          }
        })
          .then(() => {
            this.$notify.success('The email logo was changed successfully.')
            this.organisationId = null
            this.currentLogo = null
            this.form.reset()
            this.$v.form.$reset()
            this.$refs.uploader.clearFiles()
            this.queryForOrganisations()
          })
          .catch((err) => {
            this.$displayRequestError(err)
          })
      } catch (err) {}
    },

    async removeLogo () {
      try {
        this.form.setPayloadTransformer((payload) => {
          let formData = new FormData()

          // formData.append('logo', null)

          return formData
        })

        this.form.submit('post', `/staff/organisations/${this.organisationId}/set-email-logo`, {
          headers: {
            'Content-Type': 'multipart/form-data'
          }
        })
          .then(() => {
            this.$notify.success('The email logo was removed successfully.')
            this.organisationId = null
            this.currentLogo = null
            this.form.reset()
            this.$v.form.$reset()
            this.$refs.uploader.clearFiles()
            this.queryForOrganisations()
          })
          .catch((err) => {
            this.$displayRequestError(err)
          })
      } catch (err) {}
    }
  }
}
</script>

<style>
.el-upload, .el-upload-dragger {
  width: 100%;
}
</style>
